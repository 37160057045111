import React from "react";
import AmountComparison from "./amountComparison";
import { Typography } from "@material-ui/core";

const AnswersAmountComparison = ({ data, pollsSelectedColors, questionName, pollsSelected }) => {

    const filteredAnswers = data.filter((item) => item[0].description === questionName)[0];

    let i;
    const labels = [];
    const numbers1 = [];
    const numbers2 = [];

    for (i = 1; i <= filteredAnswers[0].optionsAmount; i++) {
        labels.push(filteredAnswers[0]["option" + i + "_text"]);
        numbers1.push(filteredAnswers[0]["option" + i + "_amount"]);
        numbers2.push(filteredAnswers[1]["option" + i + "_amount"]);
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
                {
                    pollsSelected.map((item, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                                <div style={{ width: 20, height: 20, background: pollsSelectedColors[index], marginRight: 10 }} />
                                <Typography className="whiteText">
                                    {item.name}
                                </Typography>
                            </div>
                        )
                    })
                }
            </div>
            <div style={{ margin: 20, display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {
                    labels.map((item, index) => {
                        return (
                            <span
                                key={index}
                                className="myDesktopShadow"
                                style={{ margin: 10, padding: 20, background: 'var(--secondaryBackgroundColor)', borderRadius: 10 }}
                            >
                                <AmountComparison
                                    title={item}
                                    colors={pollsSelectedColors}
                                    name1={pollsSelected[0].name}
                                    name2={pollsSelected[1].name}
                                    amount1={numbers1[index]}
                                    amount2={numbers2[index]}
                                />
                            </span>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default AnswersAmountComparison;
