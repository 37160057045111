import * as actionTypes from "./actionTypes";
import {
  getPollComparisonAPI,
  getPollComparisonQuestionsAPI,
  getPollComparisonAnswersAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";

export const getPollComparisonAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_POLLS });
  try {
    let response = await getPollComparisonAPI();

    dispatch({
      type: actionTypes.GET_POLLS_FULFILLED,
      payload: response.data.data,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_POLLS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getPollComparisonQuestionsAction = (pollCode1, pollCode2) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUESTIONS });
  try {
    let response = await getPollComparisonQuestionsAPI(`?pollCode1=${pollCode1}&pollCode2=${pollCode2}`);

    dispatch({
      type: actionTypes.GET_QUESTIONS_FULFILLED,
      payload: {
        questions: [response.data.poll1, response.data.poll2],
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUESTIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e;
  }
};

export const getPollComparisonAnswersAction = (pollCode1, questionCode1, pollCode2, questionCode2) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ANSWERS });
  try {
    let params = new URLSearchParams();
    params.set("pollCode1", pollCode1);
    params.set("questionCode1", questionCode1);
    params.set("pollCode2", pollCode2);
    params.set("questionCode2", questionCode2);

    let response = await getPollComparisonAnswersAPI(`?${params.toString()}`);

    dispatch({
      type: actionTypes.GET_ANSWERS_FULFILLED,
      payload: {
        answers: [response.data.poll1, response.data.poll2],
        dateFormat: response.data.date_format,
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ANSWERS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e;
  }
};