import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactSpeedometer from "react-d3-speedometer";
import { getComparisonAmountColor, getComparisonAmountDelta, getComparisonAmountIcon } from "./amountComparison";
import useNpsData from "@icarius-pages/pollReview/components/questionData/useNpsData";
import SectionContainer from "@icarius-pages/pollReview/components/questionData/sectionContainer";

const NPSData = ({ data, pollsSelected, pollsSelectedColors }) => {

    const {
        npsValue: npsValue1,
        npsVotesData: npsVotesData1,
        npsResultData: npsResultData1,
        npsResultDataItem: npsResultDataItem1,
        getOptionData: getOptionData1,
    } = useNpsData(data[0]);

    const {
        npsValue: npsValue2,
        npsVotesData: npsVotesData2,
        npsResultData: npsResultData2,
        npsResultDataItem: npsResultDataItem2,
        getOptionData: getOptionData2,
    } = useNpsData(data[1]);


    const npsVotesDataMerged = npsVotesData1.map((item, index) => ({
        title: item.title,
        color: item.color,
        count1: item.count,
        percentage1: item.percentage,
        count2: npsVotesData2[index].count,
        percentage2: npsVotesData2[index].percentage,
    }))

    return (
        <>
            <SectionContainer title="Distribución de respuestas" ignoreContainer>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <div style={{ padding: 10, margin: 10, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: 1, height: 68 }} />
                        <div style={{ display: 'flex', alignItems: 'center', height: 48 }}>
                            <div style={{ width: 20, height: 20, background: pollsSelectedColors[0], marginRight: 10 }} />
                            <Typography className="whiteText">
                                {pollsSelected[0].name}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', height: 48 }}>
                            <div style={{ width: 20, height: 20, background: pollsSelectedColors[1], marginRight: 10 }} />
                            <Typography className="whiteText">
                                {pollsSelected[1].name}
                            </Typography>
                        </div>
                    </div>
                    {
                        [...Array(10).keys()].map((item, index) => {
                            const optionData1 = getOptionData1(index);
                            const optionData2 = getOptionData2(index);
                            return (
                                <div key={item} style={{ padding: 10, margin: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {optionData1.icon}
                                    <Typography align="center" className="whiteText" style={{ paddingTop: 10, paddingBottom: 10 }}>
                                        {item + 1}
                                    </Typography>
                                    <Typography align="center" className="whiteText" style={{ fontWeight: 600, fontSize: '2rem' }}>
                                        {optionData1.votes}
                                    </Typography>
                                    <Typography align="center" className="whiteText" style={{ fontWeight: 600, fontSize: '2rem' }}>
                                        {optionData2.votes}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
            </SectionContainer>
            <Grid container item xs={12}>
                <Grid container direction='row' item xs={12} alignItems="center">
                    <SectionContainer title="Porcentajes" ignoreContainer>
                        <Grid container xs={12} lg={10} xl={8} alignItems="center" justify="center" item style={{ padding: 30, margin: '0 auto' }}>
                            <Typography className="whiteText" align="center" style={{ fontSize: 20, textDecoration: 'underline', marginBottom: 20, marginTop: -20 }}>
                                {`${pollsSelected[0].name} vs ${pollsSelected[1].name}`}
                            </Typography>
                            {
                                npsVotesDataMerged.map((item, index) => {
                                    return (
                                        <Grid key={index} container xs={12} alignItems="center" justify="center" item style={{ minHeight: 45 }}>
                                            <Grid container item xs={12} md={3} alignItems="center">
                                                <div style={{ height: 20, width: 20, backgroundColor: item?.color, marginRight: 15 }} />
                                                <Typography className='whiteText' style={{ fontSize: 20, textTransform: 'capitalize' }}>
                                                    {item.title}
                                                </Typography>
                                            </Grid>
                                            <Grid container item xs={12} md={9} alignItems="center">
                                                <Grid item xs={2}>
                                                    <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                        {item.count1}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {
                                                        Boolean(item?.percentage1) &&
                                                        <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                            {`${item.percentage1}%`}
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                        {' vs. '}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                        {item.count2}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {
                                                        Boolean(item?.percentage2) &&
                                                        <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                            {`${item.percentage2}%`}
                                                        </Typography>
                                                    }
                                                </Grid>
                                                <Grid container item xs={2} alignItems="center">
                                                    {
                                                        Boolean(item?.percentage2) &&
                                                        <>
                                                            {getComparisonAmountIcon(item.count1, item.count2)}
                                                            <Typography className='whiteText' style={{ paddingLeft: 10, fontSize: 20, fontWeight: 600 }}>
                                                                {item.count1 - item.count2}
                                                            </Typography>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </SectionContainer>
                </Grid>
                <Grid container direction='row' item xs={12}>
                    <SectionContainer title='Valor NPS' ignoreContainer>
                        <Grid container item xs={12} justify="center" style={{ marginBottom: 20 }}>
                            {
                                [
                                    {
                                        name: pollsSelected[0].name,
                                        npsValue: npsValue1,
                                        npsResultDataItem: npsResultDataItem1,
                                    },
                                    {
                                        name: pollsSelected[1].name,
                                        npsValue: npsValue2,
                                        npsResultDataItem: npsResultDataItem2,
                                    },
                                ].map((item, index) => {
                                    return (
                                        <div key={index} style={{ margin: 20 }}>
                                            <Typography className="whiteText" align="center" style={{ fontSize: 20, textDecoration: 'underline' }}>
                                                {item.name}
                                            </Typography>
                                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div
                                                    style={{
                                                        borderRadius: '50%',
                                                        border: `10px solid ${item.npsResultDataItem.color}`,
                                                        height: 200,
                                                        width: 200,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                                        {'NPS'}
                                                    </Typography>
                                                    <Typography style={{ fontSize: 40, fontWeight: 600, color: item.npsResultDataItem.color }}>
                                                        {item.npsValue}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div style={{ margin: 20 }}>
                                <Typography className="whiteText" align="center" style={{ fontSize: 20, textDecoration: 'underline' }}>
                                    {'Diferencia'}
                                </Typography>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div
                                        style={{
                                            height: 200,
                                            width: 200,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600 }}>
                                            {'NPS'}
                                        </Typography>
                                        <Typography style={{ fontSize: 40, fontWeight: 600, color: getComparisonAmountColor(npsValue1, npsValue2) }}>
                                            {`${(npsValue1 - npsValue2 <= 0 ? "" : "+")}${npsValue1 - npsValue2}`}
                                        </Typography>
                                        <Typography style={{ fontSize: 20 }}>
                                            {getComparisonAmountDelta(npsValue1, npsValue2)}
                                        </Typography>
                                        {getComparisonAmountIcon(npsValue1, npsValue2)}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </SectionContainer>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                {
                    [
                        {
                            pollName: pollsSelected[0].name,
                            npsValue: npsValue1,
                            npsResultData: npsResultData1,
                            npsResultDataItem: npsResultDataItem1,
                        },
                        {
                            pollName: pollsSelected[1].name,
                            npsValue: npsValue2,
                            npsResultData: npsResultData2,
                            npsResultDataItem: npsResultDataItem2,
                        },
                    ].map((item, index) => {
                        return (
                            <Grid key={index} container item xs={12} md={6}>
                                <SectionContainer title={item.npsResultDataItem.title} ignoreContainer useRightBorder={index === 0}>
                                    <div style={{ marginBottom: 20 }}>
                                        <Typography className="whiteText" align="center" style={{ fontSize: 20, textDecoration: 'underline', marginBottom: 30 }}>
                                            {item.pollName}
                                        </Typography>
                                        <div className="flex flex-col items-center">
                                            <ReactSpeedometer
                                                maxValue={100}
                                                minValue={-100}
                                                value={item.npsValue}
                                                segments={5}
                                                customSegmentStops={[-100, 0, 50, 75, 100]}
                                                needleColor={"#CCC"}
                                                segmentColors={item.npsResultData.map(item => item.color)}
                                                height={200}
                                                textColor={"var(--mainText)"}
                                                needleTransitionDuration={0}
                                            />
                                            <div className="flex justify-center flex-wrap" style={{ marginBottom: 20 }}>
                                                {
                                                    item.npsResultData.map((item) => {
                                                        return (
                                                            <div key={item.sectionTitle} className="flex items-center justify-center" style={{ margin: '0px 10px' }}>
                                                                <div style={{ height: 20, width: 20, backgroundColor: item.color, marginRight: 10 }} />
                                                                <Typography className='whiteText' style={{ fontSize: 20 }}>
                                                                    {item.sectionTitle}
                                                                </Typography>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </SectionContainer>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
}

export default NPSData;
