import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Grid,
  Typography,
} from "@material-ui/core";

const Container = ({ children }) => {
  return (
    <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
      <Typography
        className="whiteText"
        variant="h2"
        align="center"
        style={{ fontSize: 32, fontWeight: 700, marginBottom: 16, width: '70%' }}
      >
        {children}
      </Typography>
    </Grid>
  )
}

const StartMessage = ({ polls, filteredPolls, familySelected, typeSelected }) => {
  return (
    <>
      {
        !Boolean(polls?.length) &&
        <Container>
          {getLocalizedString("noPollReviewData")}
        </Container>
      }
      {
        Boolean(polls?.length) && (!familySelected || !typeSelected) &&
        <Container>
          {'Seleccione familia y tipo de encuesta para comenzar.'}
        </Container>
      }
      {
        Boolean(polls?.length) && familySelected && typeSelected && !Boolean(filteredPolls?.length) &&
        <Container>
          {'No se encontraron encuestas para los filtros seleccionados'}
        </Container>
      }
    </>
  );
}

export default StartMessage;
