import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { generalColors } from "@icarius-utils/colors";

export const getComparisonAmountIcon = (amount1, amount2) => {
    if (amount1 - amount2 === 0) return <DragHandleIcon style={{ fill: generalColors.blue }} />
    if (amount1 - amount2 > 0) return <ArrowUpwardIcon style={{ fill: generalColors.green }} />
    return <ArrowDownwardIcon style={{ fill: generalColors.red }} />
}

export const getComparisonAmountColor = (amount1, amount2) => {
    if (amount1 - amount2 === 0) return generalColors.blue;
    if (amount1 - amount2 > 0) return generalColors.green;
    return generalColors.red;
}

export const getComparisonAmountDelta = (amount1, amount2) => {
    if (!amount2) return '--';
    return `${Math.abs((amount1 - amount2) / (amount2) * 100).toFixed(2)}%`;
}

const AmountComparison = ({ title, colors, name1, name2, amount1, amount2 }) => {

    const delta = getComparisonAmountDelta(amount1, amount2);

    return (
        <div style={{ minWidth: 150, display: 'inline-block' }}>
            {
                Boolean(title) &&
                <Typography className="whiteText" align="center" style={{ fontSize: 20 }}>
                    {title}
                </Typography>
            }
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px auto' }}>
                <Tooltip title={name1} arrow>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, background: colors[0], marginRight: 10 }} />
                        <Typography className="whiteText">
                            {amount1}
                        </Typography>
                    </div>
                </Tooltip>
                <Tooltip title={name2} arrow>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 20, height: 20, background: colors[1], marginRight: 10 }} />
                        <Typography className="whiteText">
                            {amount2}
                        </Typography>
                    </div>
                </Tooltip>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Typography className="whiteText" style={{ fontSize: 20 }}>
                    {delta}
                </Typography>
                {getComparisonAmountIcon(amount1, amount2)}
            </div>
        </div>
    );
}

export default AmountComparison;
