import React from "react";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getTheme } from "@icarius-pages/login/selectors";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CommonPage from "@icarius-common/commonPage";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import QuestionData from "./questionData/questionData";
import usePollComparison from "./usePollComparison";
import StartMessage from "./startMessage";
import { shadeHexColor } from "@icarius-utils/colors";
import ParticipationData from "@icarius-pages/pollReview/components/participationData";
import PollSelection from "@icarius-pages/pollReview/components/pollSelection";
import QuestionSlider from "@icarius-pages/pollReview/components/questionSlider";

const PollComparison = () => {

  const {
    divRef,
    familyList,
    typeList,
    polls,
    filteredPolls,
    commonQuestions,
    answers,
    isAnonymous,
    isNPS,
    societySelected,
    dateFormat,
    locale,
    country,
    isLoading,
    state,
    handlers,
  } = usePollComparison();

  const { theme } = useSelector(getTheme);
  const color = useSelector(getAppColor);
  const pollsSelectedColors = [shadeHexColor(color, 0.3), shadeHexColor(color, 0.6)];

  const history = useHistory();

  const helpItems = [
    <MenuItemWithIcon
      key={"1"}
      value={"https://www.youtube.com/embed/gvm4lL3KZlo"}
      text={getLocalizedString("pollSliderTitle")}
      type={"video"}
    />
  ]

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString('goBack')}
      onClick={() => history.push(paths.pollScreenSelector)}
      type={'goBack'}
    />
  )

  const customHeader = () => Boolean(familyList.length) && (
    <>
      <FormControl style={{ width: 200, marginRight: 10 }}>
        <InputLabel required id={'label-family'}>{'Familia'}</InputLabel>
        <Select
          labelId={'label-family'}
          id={'select-family'}
          value={state.familySelected}
          onChange={e => handlers.handleSelectFamily(e.target.value)}
        >
          {
            familyList.map(item => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControl style={{ width: 200 }}>
        <InputLabel required id={'label-type'}>{'Tipo'}</InputLabel>
        <Select
          labelId={'label-type'}
          id={'select-type'}
          value={state.typeSelected}
          onChange={e => handlers.handleSelectType(e.target.value)}
        >
          {
            typeList.map(item => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </>
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Comparación de encuestas'}
      menuItems={[goBackButton]}
      customHeader={customHeader}
      helpItems={helpItems}
      isNotGridPage
      hasHelp
    >
      {
        !isLoading &&
        <StartMessage
          polls={polls}
          filteredPolls={filteredPolls}
          familySelected={state.familySelected}
          typeSelected={state.typeSelected}
        />
      }
      {
        Boolean(filteredPolls.length) &&
        <>
          <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content">
            <PollSelection
              data={filteredPolls}
              color={color}
              pollsSelected={state.pollsSelected}
              pollsSelectedColors={pollsSelectedColors}
              handlePollClick={handlers.handleSelectPoll}
            />
          </Grid>
          {
            Boolean(state.pollsSelected.length > 1) &&
            <ParticipationData
              data={state.pollsSelected}
              color={color}
              pollsSelectedColors={pollsSelectedColors}
            />
          }
          {
            Boolean(state.pollsSelected.length > 1) &&
            <QuestionSlider
              key={`${state.pollsSelected[0].code}_${state.pollsSelected[1].code}`}
              data={commonQuestions}
              color={color}
              colors={pollsSelectedColors}
              theme={theme}
              handleQuestionClick={handlers.handleSelectQuestion}
            />
          }
          {
            Boolean(state.pollsSelected?.length > 1) && Boolean(answers.length) &&
            <QuestionData
              isNPS={isNPS}
              isAnonymous={isAnonymous}
              pollsSelected={state.pollsSelected}
              questions={commonQuestions}
              data={answers}
              type={state.questionType}
              name={state.questionName}
              sentiments={state.sentiments}
              dateFormat={dateFormat}
              societySelected={societySelected}
              locale={locale}
              country={country}
              theme={theme}
              color={color}
              pollsSelectedColors={pollsSelectedColors}
              ref={divRef}
            />
          }
        </>
      }
    </CommonPage>
  );
}

export default PollComparison;
