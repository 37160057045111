import React, { useState } from "react";
import { Grid, Divider, Typography } from "@material-ui/core";
import WordsData from './wordsData';
import NPSData from "./npsData";
import AnswersAmountComparison from "./answersAmountComparison";
import paths from "@icarius-localization/paths";
import GridData from "@icarius-pages/pollReview/components/questionData/gridData";

const QuestionData = React.forwardRef((props, ref) => {

    const [count, setCount] = useState(0);

    // estado usado solamente para que se haga rerender en GridData cuando se cierre la grilla de sentimientos, 
    // y se vuelva a setear correctamente su context, sino, al hacer un filtro por ejemplo, intenta aplicarlo en el context de la grilla de sentimientos
    // que es la ultima que se crea
    // es horrible pero es la unica forma de forzarlo sin tener que modificar todos los setCtx par que se guarden en un store general en lugar de una variable sin poder recuperar el valor anterior

    const {
        isNPS,
        data,
        name,
        type,
        questions,
        sentiments,
        color,
        pollsSelectedColors,
        dateFormat,
        societySelected,
        locale,
        theme,
        country,
        isAnonymous,
        pollsSelected,
    } = props;

    const gridData = [
        ...data[0].map((item) => ({
            ...item,
            Encuesta: pollsSelected[0].name,
        })),
        ...data[1].map((item) => ({
            ...item,
            Encuesta: pollsSelected[1].name,
        })),
    ];

    const gridPath = (() => {
        if (isNPS) return isAnonymous ? 'pollComparisonNPS' : "nonAnonymousPollComparisonNPS";
        return isAnonymous ? paths.pollComparison : "nonAnonymousPollComparison";
    })();

    return (
        <Grid item xs={12}>
            <div className={"slider-container"} ref={ref} style={{ padding: 0 }}>
                <Typography className={"title"}>
                    {`Respuestas de "${name}"`}
                </Typography>
                <Divider style={{ background: color, height: 2 }} />
            </div>
            {
                type === 'D' &&
                <WordsData
                    pollsSelected={pollsSelected}
                    questionData={[...data[0], ...data[1]]}
                    sentiments={sentiments}
                    pollsSelectedColors={pollsSelectedColors}
                    forceGridDataUpdate={() => setCount(prev => prev + 1)}
                />
            }
            {
                isNPS && type === 'O' &&
                <NPSData
                    data={data}
                    pollsSelected={pollsSelected}
                    pollsSelectedColors={pollsSelectedColors}
                />
            }
            {
                type === 'O' && !isNPS &&
                <AnswersAmountComparison
                    data={questions}
                    questionName={name}
                    pollsSelected={pollsSelected}
                    pollsSelectedColors={pollsSelectedColors}
                />
            }
            <GridData
                data={gridData}
                dateFormat={dateFormat}
                societySelected={societySelected}
                locale={locale}
                country={country}
                count={count}
                color={color}
                gridTheme={{ theme }}
                path={gridPath}
                isNPS={isNPS}
            />
        </Grid>
    );
})

export default QuestionData;
