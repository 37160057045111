import NAME from "./constants";

export const GET_POLLS = NAME + "/GET_POLLS";
export const GET_POLLS_FULFILLED = NAME + "/GET_POLLS_FULFILLED";
export const GET_POLLS_REJECTED = NAME + "/GET_POLLS_REJECTED";

export const GET_QUESTIONS = NAME + "/GET_QUESTIONS";
export const GET_QUESTIONS_FULFILLED = NAME + "/GET_QUESTIONS_FULFILLED";
export const GET_QUESTIONS_REJECTED = NAME + "/GET_QUESTIONS_REJECTED";

export const GET_ANSWERS = NAME + "/GET_ANSWERS";
export const GET_ANSWERS_FULFILLED = NAME + "/GET_ANSWERS_FULFILLED";
export const GET_ANSWERS_REJECTED = NAME + "/GET_ANSWERS_REJECTED";