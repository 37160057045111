import { getSocietySelected } from "@icarius-pages/login/selectors";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountry, getIsExportingGrid as getExportingGrid } from "src/app/selectors";
import {
    getPollComparisonQuestionsAction,
    getPollComparisonAnswersAction,
    getPollComparisonAction,
} from "../actions";
import {
    getQuestions,
    getDateFormat,
    getAnswers,
    getLocale,
    getPolls,
    getIsLoading,
} from "../selectors";
import { useMemo } from "react";
import _ from "lodash";

const usePollComparison = () => {

    const [familySelected, setFamilySelected] = useState('');
    const [typeSelected, setTypeSelected] = useState('');

    const [pollsSelected, setPollsSelected] = useState([]);
    const [questionName, setQuestionName] = useState(null);
    const [questionType, setQuestionType] = useState(null);
    const [sentiments, setSentiments] = useState(null);
    const divRef = useRef();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPollComparisonAction());
    }, [dispatch])

    const handleClearPollsSelected = () => {
        setPollsSelected([]);
    };

    const handleSelectFamily = (value) => {
        setFamilySelected(value);
        handleClearPollsSelected();
    }

    const handleSelectType = (value) => {
        setTypeSelected(value);
        handleClearPollsSelected();
    }

    const handleSelectPoll = (newPoll) => {
        if (!pollsSelected.length) {
            setPollsSelected([newPoll]);
            return;
        }

        // si ya estaba seleccionado, deseleccionar
        const pollIndex = pollsSelected.findIndex((item) => item.code === newPoll.code);
        if (pollIndex !== -1) {
            const newPollsSelected = [...pollsSelected];
            newPollsSelected.splice(pollIndex, 1)
            setPollsSelected(newPollsSelected);
            return;
        }

        if (pollsSelected.length === 1 && pollsSelected[0].code !== newPoll.code) {
            setPollsSelected((prev) => [...prev, newPoll]);

            dispatch(getPollComparisonQuestionsAction(pollsSelected[0].code, newPoll.code))
                .then((resp) => {
                    if (resp.status === 200) {
                        const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
                        const element = document.getElementById('participationData');
                        const posi = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                        setTimeout(() => {
                            window.scrollTo({ top: posi, behavior: 'smooth' });
                        }, 200);
                    }
                })
        }
    };

    const handleSelectQuestion = (questionData) => {
        setQuestionName(questionData[0].description);
        setQuestionType(questionData[0].type);
        setSentiments([
            questionData[0].sentiments,
            questionData[1].sentiments,
        ]);

        dispatch(getPollComparisonAnswersAction(pollsSelected[0].code, questionData[0].code, pollsSelected[1].code, questionData[1].code))
            .then((resp) => {
                if (resp.status === 200) {
                    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
                    const element = divRef.current;
                    const posi = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    setTimeout(() => {
                        window.scrollTo({ top: posi, behavior: 'smooth' });
                    }, 200);
                }
            })
    };

    const societySelected = useSelector(getSocietySelected);
    const dateFormat = useSelector(getDateFormat);
    const locale = useSelector(getLocale);
    const country = useSelector(getCountry);
    const isExportingGrid = useSelector(getExportingGrid);
    const isLoadingAnalytics = useSelector(getIsLoading);
    const polls = useSelector(getPolls);
    const questions = useSelector(getQuestions);
    const answers = useSelector(getAnswers);

    const filteredPolls = useMemo(() => {
        return polls.map((item) => item.polls).flat(1).filter((poll) => {
            return poll.family === familySelected && poll.type === typeSelected;
        })
    }, [familySelected, typeSelected, polls])


    const familyList = useMemo(() => {
        const familyArray = polls.map((item) => item.polls).flat(1).map((item) => item.family);
        return Array.from([...new Set(familyArray)]).map((item) => ({ key: item, value: item }));
    }, [polls]);

    const typeList = [
        { key: 'C', value: 'Comparativa' },
        { key: 'N', value: 'NPS' },
    ];

    const isAnonymous = (() => {
        if (pollsSelected.length !== 2) return true;
        if (!questions[0] || !questions[1]) return true;
        return questions[0].isAnonymous || questions[1].isAnonymous;
    })();

    const isNPS = typeSelected === 'N'

    const commonQuestions = useMemo(() => {
        const createQuestionToCompare = (question) => {
            const questionToCompare = { ...question };
            delete questionToCompare.code;
            delete questionToCompare.orden;
            delete questionToCompare.answersAmount;
            delete questionToCompare.responses;
            delete questionToCompare.sentiments;


            const keysToDelete = Object.keys(questionToCompare).filter((item) => item.includes("_amount"));
            keysToDelete.forEach((item) => {
                delete questionToCompare[item];
            })

            return questionToCompare;
        }

        if (pollsSelected.length !== 2) return [];
        if (!questions[0]?.questions?.length || !questions[1]?.questions?.length) return [];
        if (questions[0]?.questions?.length !== questions[1]?.questions?.length) return [];

        const auxCommonQuestions = [];

        questions[0].questions.forEach((question) => {
            const sameQuestionFromOtherPoll = questions[1].questions.find((item) => item.description === question.description);
            if (_.isEqual(createQuestionToCompare(question), createQuestionToCompare(sameQuestionFromOtherPoll))) {
                auxCommonQuestions.push([
                    { ...question, pollName: pollsSelected[0].name },
                    { ...sameQuestionFromOtherPoll, pollName: pollsSelected[1].name }
                ])
            }
        })

        return auxCommonQuestions;
    }, [questions, pollsSelected]);

    const isLoading = (isLoadingAnalytics || isExportingGrid);

    const state = {
        familySelected,
        typeSelected,
        pollsSelected,
        questionName,
        questionType,
        sentiments,
    };

    const handlers = {
        handleSelectFamily,
        handleSelectType,
        handleSelectPoll,
        handleSelectQuestion,
    };

    return {
        divRef,
        familyList,
        typeList,
        polls,
        filteredPolls,
        commonQuestions,
        answers,
        isAnonymous,
        isNPS,
        societySelected,
        dateFormat,
        locale,
        country,
        isLoading,
        state,
        handlers,
    }
}

export default usePollComparison;
